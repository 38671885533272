<template>
    <footer>
        <div class="nav">
            <div class="top">
                <div class="branding">
                    <router-link class="header" :to="{ name: 'home' }"> BrandRatio </router-link> 
                </div>
                <!-- <div class="nav-links">
                    <ul>
                        <router-link class="link" :to="{ name: 'home' }">Privacy Policy</router-link>
                        <router-link class="link" :to="{ name: 'home' }">Cookie Policy</router-link>
                        <router-link class="link" :to="{ name: 'home' }">Termini e condizioni di vendita</router-link>
                    </ul>
                </div> -->
            </div>
            <div class="bottom">
                <div class="left-info">
                    <ul class="info-list">
                        <!-- <li>Nome compagnia</li>
                        <li>Partita Iva</li> -->
                        <li>
                            <a href="mailto:ai@brandratio.it" class="mail">
                                ai@brandratio.it
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'FooterPage'
}
</script>

<style lang="scss" scoped>
footer {
    position: absolute;
    bottom: -200px;
    width: 100%;
    background-color: #092b20;
    padding: 0 25px 0 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);
    z-index: 99;
}

.nav {
    display: flex;
    flex-direction: column;
    padding: 25px 0;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.branding {
    font-size: 24px;
    margin-left: 50px;

    .header {
        text-decoration: none;
        color: #fff;
    }
}

.nav-links {
    display: flex;
    align-items: center;

    ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.link {
    font-weight: 500;
    padding: 0 10px;
    transition: .3s color ease;
    color: #fff;

    &:hover {
        color: #1eb8b3;
    }
}

.bottom {
    padding-top: 10px;
}

.left-info {
    color: #fff;
    margin-left: 50px;
}

.info-list {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
}

.mail {
    color: #fff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@media (max-width: 771px) {
    .nav-links ul {
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
    }

    .link {
        padding: 5px 0;
    }
}

@media (max-width: 520px) {
    .nav {
        flex-direction: column;
    }
    
    .top {
        order: 1;
    }

    .bottom {
        order: 2;
    }

    .nav-links {
        order: 3;
        width: 100%;
        ul {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
</style>
